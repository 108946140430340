import _ from "lodash";
import Vue from "vue";
import Vuex from "vuex";

import { UserService, SettingsService } from "@/core/services";
import { MsalUtility } from "@/core/utilities/msal.utility";
import { PagedCollectionFilterDictionary } from "@/core/models";

Vue.use(Vuex);

const RoleListStorageKey = "iris-user-role-list";
const UserIdStorageKey = "iris-user-id";
const CanPerformQualityAuditsStorageKey = "iris-can-perform-quality-audits";

function getRoleListFromStorage() {
  return sessionStorage.getItem(RoleListStorageKey);
}

function getUserIdFromStorage() {
  const id = sessionStorage.getItem(UserIdStorageKey);

  if (!!id) {
    return +id;
  }
}

function getCanPerformQualityAuditsFromStorage() {
  const value = sessionStorage.getItem(CanPerformQualityAuditsStorageKey);
  if (value == null) {
    return null;
  }

  return value === "true";
}

function setCanPerformQualityAuditsFromStorage(value: boolean) {
  const valueToStore = value
    ? "true"
    : "";

  sessionStorage.setItem(CanPerformQualityAuditsStorageKey, valueToStore);
}

/**
 * Vuex Store for Iris
 *
 * TODO: This store is NOT strongly typed. It should be.
 *   I did not have the time to look into and implementing strongly typing
 *   the store. Maybe we could do it in another project!
 *   Examples:
 *     - https://next.vuex.vuejs.org/guide/typescript-support.html
 *     - https://medium.com/swlh/properly-typed-vuex-stores-427bf4c6a3d1
 */
export default new Vuex.Store({
  state: {
    userRoleList: getRoleListFromStorage(),
    canPerformQualityAudits: getCanPerformQualityAuditsFromStorage(),
    isFetchingUserRoles: false,
    listFilters: {} as PagedCollectionFilterDictionary,
  },
  getters: {
    userRoleList(state: any) {
      // TODO: Put a time limit (e.g. 5 minutes?) on the roles that can be stored
      // in state or storage.

      return state.userRoleList !== null
        ? state.userRoleList
        : getRoleListFromStorage();
    },
    canPerformQualityAudits(state: any) {
      return state.canPerformQualityAudits !== null
        ? state.canPerformQualityAudits
        : getCanPerformQualityAuditsFromStorage();
    },
    userRoles(state: any, getters: any): string[] {
      if (!state.userRoleList) {
        return [];
      }

      return String(state.userRoleList).split(",");
    },
    userId(state: any) {
      return getUserIdFromStorage();
    },
    listFilters(state: any) {
      return state.listFilters;
    },
  },
  mutations: {
    setRoleList(state, payload) {
      state.userRoleList = payload;

      if (String(payload)) {
        sessionStorage.setItem(RoleListStorageKey, payload);
      } else {
        // There is nothing to store so remove values from localstorage
        sessionStorage.removeItem(RoleListStorageKey);
      }
    },
    setCanPerformQualityAudits(state, payload) {
      state.canPerformQualityAudits = payload;
      setCanPerformQualityAuditsFromStorage(payload);
    },
    setUserId(state, payload) {
      state.userId = payload;

      if (String(payload)) {
        sessionStorage.setItem(UserIdStorageKey, payload);
      } else {
        // There is nothing to store so remove values from localstorage
        sessionStorage.removeItem(UserIdStorageKey);
      }
    },
    setListFilters(state, payload) {
      state.listFilters = payload;
    },
  },
  actions: {
    setUserRoles({commit, state}) {
      if (state.isFetchingUserRoles) {
        return;
      }

      state.isFetchingUserRoles = true;
      UserService.getMyUserInfo()
        .then((userInfo) => {
          commit("setUserId", userInfo.userId);
          commit("setRoleList", userInfo.roles);
          commit("setCanPerformQualityAudits", userInfo.canPerformQualityAudits);
        })
        .catch((e) => {
          if (e && e.response && e.response.status === 401) {
            // This user does not have the permissions to access the backend
            const noAccessUrl = "/" + SettingsService.getAppOwnerNoAccessPage();
            MsalUtility.logout(noAccessUrl);
          }
        })
        .finally(() => {
          state.isFetchingUserRoles = false;
        });
    },

    clearUserRoles({commit, state}) {
      commit("setRoleList", "");
      commit("setUserId", "");
    },
  },
});
