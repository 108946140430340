export default {
  "common": {
    "name": "Nombre",
    "hello": "Hola",
    "details": "Datos",
    "created": "Creado",
    "ok": "OK",
    "cancel": "Cancelar",
    "save": "Guardar",
    "confirm": "Confirmar",
    "saveAsDraft": "Guardar como borrador",
    "unpublish": "Anular publicación",
    "id": "Id",
    "search": "Buscar",
    "status": "Estatus",
    "view": "Ver",
    "course": "Curso",
    "union": "Unión",
    "noData": "No hay Datos",
    "startDate": "Fecha de comienzo",
    "startTime": "Hora de comienzo",
    "endDate": "Fecha de finalización",
    "notes": "Notas",
    "educators": "Educadores",
    "forUser": "Para el usuario",
    "regions": "Regiones",
    "unions": "Uniones",
    "existingRegions": "Regiones existentes",
    "existingUnions": "Uniones existentes",
    "homeUnion": "Unión de origen",
    "expiresOn": "Vence el",
    "selectUser": "Seleccionar Usuario",
    "none": "Ninguno",
    "level": "Nivel",
    "actionAddAll": "Agregar todos",
    "reason": "Motivo",
    "gettingData": "Obteniendo los datos",
    "success": "Éxito",
    "error": "Error",
    "actionEdit": "Editar",
    "actionSubmit": "Enviar",
    "logo": "Logo",
    "actionDropFile": "Arrastre el archivo aquí o",
    "imageFormatsAllowed1": "Se permiten archivos JPG/PNG/GIF ",
    "forename": "Nombre",
    "lastname": "Apellido",
    "emailAddress": "Email ",
    "email": "Email",
    "validationIsRequired": "obligatorio",
    "code": "Código",
    "certified": "Certificado",
    "certExpiry": "Vencimiento del certificado",
    "session": "Sesión",
    "yes": "Sí",
    "no": "No ",
    "actionDownload": "Descargar",
    "close": "Cerrar ",
    "user": "Usuario",
    "location": "Ubicación",
    "from": "Desde",
    "to": "Hasta",
    "type": "Tipo",
    "validationError": "Error de validación",
    "validationErrorDescription": "Se ha producido un error de validación. Por favor, corríjalo",
    "errorSaving": "Se ha producido un error al guardar en la base de datos",
    "saving": "Guardando...",
    "addedOn": "Agregado en",
    "address": "Dirección",
    "dateOfBirth": "Fecha de nacimiento",
    "reject": "Rechazar",
    "saveAndProgressToNextStage": "Guardar y pasar a la etapa siguiente",
    "nextStageIs": "La siguiente etapa es",
    "warning": "Advertencia",
    "auditors": "Auditores",
    "firstName": "Nombre",
    "surname": "Apellido"
  },
  "navigation": {
    "courses": "Cursos ",
    "myCourses": "Mis cursos ",
    "courseAdmin": "Admin de cursos ",
    "training": "Capacitación",
    "mySessions": "Mis sesiones",
    "sessionAdmin": "Admin de sesiones",
    "dashboard": "Tablero de control",
    "navAbout": "Sobre",
    "navAdministration": "Administración",
    "navUnions": "Uniones",
    "logout": "Desconectar",
    "myProfile": "Mi perfil ",
    "coursesAttended": "Cursos realizados ",
    "licensesAwarded": "Licencias recibidas ",
    "certification": "Certificación",
    "fileshare": "Archivos compartidos",
    "correspondence": "Correspondencia"
  },
  "mySessions": {
    "viewMyTrainingSession": "Ver mis sesiones de capacitación",
    "hostTrainingSession": "Organizar una sesión de capacitación",
    "selectCourse": "Seleccione el curso",
    "selectUnion": "Seleccione la unión",
    "selectLicense": "Seleccione la licencia",
    "licenseInfo": "Información de la licencia",
    "validIn": "Válido en",
    "language": "Idioma",
    "unionToAdmin": "Unión a administrar"
  },
  "filters": {
    "filterByRegion": "Filtrar por región",
    "filterByUnion": "Filtrar por unión",
    "filterByStatus": "Filtrar por estado",
    "filterByQualityStatus": "Filtrar por estado de calidad",
    "filterByLevel": "Filtrar por nivel",
    "filterByStrand": "Filtrar por rama",
    "filterByExpiry": "Filtrar por vigencia",
    "filterByDeliveryType": "Filtrar por tipo de entrega",
    "pleaseSelectLevel": "Seleccione el nivel",
    "pleaseSelectStrand": "Seleccione la rama",
    "pleaseSelectRegions": "Seleccione las regiones",
    "pleaseSelectUnions": "Seleccione las uniones",
    "pleaseSelectStatus": "Seleccione el estado ",
    "filterByLanguage": "Filtrar por idioma",
    "filterByCourse": "Filtrar por curso",
    "filterByAttendeeRegion": "Filtrar por región de los participantes",
    "filterByAttendeeUnion": "Filtrar por unión de los participantes",
    "filterByUserEmail": "Filtrar por correo electrónico del usuario",
    "filterByAssessmentStatus": "Filtrar por estado de la evaluación",
    "filterByQualityAuditStatus": "Filtrar por estado de la auditoría"
  },
  "sessionAdmin": {
    "viewScheduledTrainingSession": "Ver sesiones de capacitación programadas",
    "scheduleTrainingSession": "Programar una sesión de capacitación",
    "addTrainingSession": "Agregar sesión de capacitación "
  },
  "editTrainingSession": {
    "editTrainingSession": "Editar sesión de capacitación",
    "scheduledTraining": "Capacitación programada",
    "certificateLanguage": "Idioma del certificado",
    "venueName": "Nombre de la sede",
    "venueAddress": "Dirección de la sede",
    "organiser": "Organizador",
    "selectAnEducator": "Seleccione un Educador",
    "addEducator": "Agregar Educador",
    "addNoteToHistory": "Agregar nota al historial",
    "approve": "Aprobar",
    "saveTrainingSession": "Guardar sesión de capacitación",
    "viewTrainingSessionHistory": "Ver historial de la sesión de capacitación",
    "noHistory": "No hay historial que mostrar",
    "addEducatorWarning": "Este educador puede asistir pero no puede dirigir esta sesión. Cada sesión requiere al menos un educador principal.",
    "assistingEducators": "Educadores participantes",
    "canLead": "¿Puede dirigir?",
    "selectLanguage": "Por favor, seleccione el idioma",
    "scheduleCourse": "Programar un curso",
    "saveCourse": "Guardar curso",
    "addCourse": "Agregar curso",
    "emailLanguageTooltip": "Los correos electrónicos a los participantes están redactados en este idioma",
    "startTimeLocalTime": "La hora de comienzo se especifica en hora local "
  },
  "addNote": {
    "addNote": "Agregar una nota",
    "noteDetails": "Detalles de la nota"
  },
  "courseSessionStatus": {
    "awaitingApproval": "Esperando aprobación",
    "rejected": "Rechazado",
    "approved": "Aprobado"
  },
  "tabs": {
    "tabattendees": "Participantes",
    "tabhistory": "Historial",
    "tabCourseMaterials": "Materiales del curso "
  },
  "addCourseAttendees": {
    "titleViewTrainingSessionAttendees": "Ver los participantes a la sesión de capacitación",
    "editAttendeeList": "Editar lista de participantes",
    "attendeeName": "Nombre",
    "assessmentStatus": "Estado de la evaluación",
    "actions": "Acciones",
    "editAttendees": "Editar participantes",
    "actionConfirm": "Confirmar",
    "noUsersFound": "No se encontraron usuarios",
    "attendeeUpdateError": "Error de actualización de participantes",
    "attendeeUpdateErrorDescription": "Se ha producido un error al actualizar la lista de participantes. ¿Se ha configurado una plantilla de email para la sesión regional?",
    "optionSelectStatus": "Seleccionar estado",
    "actionUpdateStatus": "Actualizar estado",
    "actionAdd": "Agregar",
    "titleAddAssessment": "Agregar evaluación",
    "selectLanguage": "Seleccione el idioma",
    "actionLicense": "Licencia",
    "actionLicenseDescription": "Conceder una licencia a este participante",
    "actionEdit": "Editar",
    "unmetPrerequisites": "Requisitos previos no cumplidos",
    "approveAreYouSure": "¿Está seguro que desea aprobar la certificación para los participantes seleccionados?",
    "approveCertification": "Aprobar la certificación",
    "errorUpdatingStatusTitle": "Error al actualizar el estado",
    "errorUpdatingStatusMessage": "Se ha producido un error al actualizar el estado de los participantes",
    "sendAttendeeInvites": "Enviar correo electrónico de invitación a los nuevos participantes:",
    "sendAttendeeInvitesToolTip": "Los correos electrónicos de invitación a los participantes están desactivados por defecto por la unión anfitriona"
  },
  "editAssessment": {
    "editAssessment": "Editar evaluación",
    "competency": "Competencia",
    "notYetCompetent": "Aún No Competente",
    "notCompetent": "Aún No Competente",
    "competent": "Competente",
    "excellent": "Excelente",
    "criteria": "Criterios",
    "commentHelpText": "Comentarios - Obligatorio para \"Aún No Competente\"",
    "actionSaveAndPublish": "Guardar y publicar",
    "assessorComment": "Comentario del evaluador",
    "publishedBy": "Publicado por",
    "lastUpdatedBy": "Actualizado la última vez por",
    "addedBy": "Agregado por",
    "errorSavingAssessment": "Error al guardar la evaluación",
    "errorSavingAssessmentMessage": "Compruebe si todas las entradas son válidas",
    "confirmCancelAssessment": "¿Está seguro de que quiere cancelar?",
    "leaveAssessment": "¿Dejar la evaluación?",
    "confirmPublishAssessment": "¿Está seguro de que quiere publicar esta evaluación? Una vez publicada, la evaluación no puede ser editada",
    "publishAssessment": "¿Publicar la evaluación?",
    "viewAssessment": "Ver la evaluación",
    "attendeeSelfReflection": "Autorreflexión del participante",
    "postCourseProgress": "",
    "postLiveExperienceProgress": "",
    "attendeeNotCompetentHeading": "¿Será competente algún día el participante?",
    "attendeeMayBeCompetent": "El participante aún puede ser competente (por defecto)",
    "attendeeNotCompetent": "El participante nunca será competente",
    "attendeeNotCompetentConfirmation": "¿Está seguro que desea publicar esta evaluación como No competente?",
    "unpublishAssessmentConfirmation": "¿Está seguro que desea anular la publicación de esta evaluación?",
    "infoPublishAssessment": "Cuando el participante haya completado la evaluación podrá publicarse marcando la casilla que sigue",
    "publishAssessmentSwitchLabel": "¿Desea publicar la evaluación?",
    "publishAssessmentActiveLabel": "Publicar la evaluación",
    "publishAssessmentInactiveLabel": "Solamente guardar y validar la evaluación (por defecto)"
  },
  "feedback": {
    "viewFeedback": "Ver retroalimentación"
  },
  "activeCerts": {
    "activeCertificateStatistics": "Estadísticas de certificados activos",
    "date1": "Fecha 1",
    "date2": "Fecha 2",
    "activeCerts": "Certificados activos",
    "pickDate": "Seleccione  una fecha ",
    "total": "Total "
  },
  "issuedCerts": {
    "issuedCertificateStatistics": "Estadísticas de certificados emitidos",
    "filterByDeliveryType": "Filtro por tipo de entrega",
    "range1": "Rango 1",
    "range2": "Rango 2",
    "dateTo": "A",
    "regionUserFilters": "Filtros por Región/Usuario",
    "regionUserFiltersDescription1": "Los filtros por región y unión se aplican de forma diferente según el tipo de curso impartido:",
    "regionUserFiltersDescription2": "Online: Las estadísticas se agrupan por la unión registrada por el asistente",
    "regionUserFiltersDescription3": "Presencial: Las estadísticas se agrupan por la unión en la que se impartió la capacitación",
    "courseCode": "Código del curso",
    "courseName": "Nombre del curso",
    "issuedCerts1": "Certificados emitidos 1",
    "issuedCerts2": "Certificados emitidos 2",
    "percentageIncrease": "% de aumento",
    "range1Start": "Inicio rango 1",
    "range1End": "Fin rango 1",
    "range2Start": "Inicio rango 2",
    "range2End": "Fin rango 2"
  },
  "strands": {
    "strandRugbyReady": "Rugby Ready",
    "strandLaws": "Leyes del Juego",
    "strandStrengthAndConditioning": "Preparación física",
    "strandPlayerWelfare": "Bienestar del jugador",
    "strandKeepRugbyClean": "Keep Rugby Clean",
    "strandCoaching": "Entrenadores",
    "strandOfficiating": "Arbitraje",
    "strandIntegrity": "Integridad",
    "strandMatchDayStaff": "Personal del Día del Partido",
    "strand": "Rama"
  },
  "licenseStatus": {
    "licenseActive": "Activo",
    "licenseRevoked": "Revocado",
    "licenseExpired": "Vencido",
    "licenseProvisional": "Provisional"
  },
  "licenses": {
    "licenses": "Licencias",
    "grantEducatorLicense": "Otorgar licencia de Educador",
    "actionRevoke": "Revocar",
    "grantALicense": "Otorgar una licencia",
    "grantLicense": "Otorgar licencia",
    "certificatesAwarded": "Certificados concedidos",
    "actionGrantALicense": "Otorgar una licencia",
    "licenseRevoke": "Revocación de licencia",
    "revokeLicense": "Revocar licencia",
    "noRevokeEmailHeader": "No hay correo electrónico de revocación automática",
    "noRevokeEmailBody": "No se envía un correo electrónico automático cuando se revoca una licencia. Los administradores pueden querer comunicar esta acción personalmente",
    "viewEligibleCourses": "Ver cursos elegibles",
    "eligibleCourses": "Cursos elegibles ",
    "messageLicenceAwardDelay": "Hay un retraso entre el otorgamiento de un certificado a un asistente y la emisión del certificado. La tabla que sigue sólo muestra los certificados otorgados por lo que es posible que un certificado otorgado a un asistente recientemente no aparezca en la tabla que sigue",
    "messageEducatorCanTeach": "Un Educador con una licencia propuesta puede dictar los siguientes cursos",
    "licenseAwardedTo": "Licencia otorgada a",
    "errorAddingLicense": "Se ha producido un error al agregar esta licencia",
    "gettingLicenseInformation": "Obteniendo información de la licencia",
    "noLicenses": "Usted no tiene actualmente ninguna licencia activa ",
    "licenseHistory": "Historial de licencias",
    "qualityStatus": "Estado de calidad",
    "delayIssuingCertificate": "Nota: Retraso en la emisión de certificados",
    "delayIssuingCertificateInfo": "Hay un retraso entre el otorgamiento de un certificado a un participante y la emisión del certificado al participante. La tabla que sigue sólo muestra los certificados otorgados por lo que es posible que un certificado otorgado recientemente a un participante no aparezca en la tabla que sigue ",
    "noActiveLicenses": "No hay licencias activas",
    "addLicenseError": "Se ha producido un error al agregar esta licencia",
    "myLicenses": "Mis licencias",
    "emailWorkforce": "Email workforce",
    "licenseAmended": "La licencia ha sido modificada correctamente",
    "licenseAmendedConfirmationHeading": "¿Modificar la licencia?",
    "licenseAmendConfirmation": "¿Está seguro de que desea modificar esta licencia? Tenga en cuenta que esta actualización no se puede deshacer.",
    "expireLicense": "Expirar la licencia",
    "amendLicense": "Modificar la licencia"
  },
  "regionAdmin": {
    "rtmUser": "Usuario RTM",
    "saveRegion": "Guardar región"
  },
  "unionAdmin": {
    "unionTrainingEducationAdministrator": "Administrador de Capacitación de la Unión",
    "canDeliverCoursesForWr": "Puede dictar cursos en nombre de World Rugby",
    "saveUnion": "Guardar Unión",
    "gettingUnion": "Obtener Unión",
    "unionSaved": "Unión guardada"
  },
  "certStatus": {
    "certStatusInProgress": "En curso",
    "certStatusValid": "válido",
    "certStatusInExpired": "Vencido",
    "certStatusAll": "Todo"
  },
  "userAdmin": {
    "userList": "Lista de usuarios",
    "inviteUser": "Invitar usuario",
    "infoNoEmailInviteHeader": "No se enviará ningún email de invitación",
    "infoNoEmailInviteBody1": "Esta función agrega un usuario a la base de datos con el rol de soporte de Iris ",
    "infoNoEmailInviteBody2": "No se envía ningún email de invitación al usuario como parte de esta función, por lo que es responsabilidad de los administradores de Iris notificarles que han sido agregados",
    "infoNoEmailInviteBody3": "Si el usuario aún no lo ha hecho, debe registrarse en el SSO de WR utilizando la dirección de email proporcionada aquí",
    "actionAddUserAndAssignBackendRole": "Agregar usuario y asignar rol de soporte",
    "irisUserAddedHeader": "Usuario de soporte de Iris agregado",
    "irisUserAddedBody": "ha sido agregado como usuario de soporte de Iris",
    "errorAddingUserHeader": "Error al agregar el usuario de soporte ",
    "errorAddingUserBody": "Se ha producido un error al agregar el usuario de soporte ",
    "isExactEmail": "Es el correo electrónico exacto",
    "userBaseId": "ID de la base de usuarios",
    "lastLoginDate": "última fecha de inicio de sesión",
    "coursesAndCertificates": "Cursos y certificados",
    "roles": "Roles",
    "currentValidCertificates": "Certificados actuales válidos",
    "userLicenses": "Licencias de usuario",
    "userNeverGrantedLicense": "Este usuario nunca ha recibido una licencia",
    "infoRegionManagerheader": "Rol de Gerente Regional de Capacitación",
    "infoRegionManagerDetail1": "La función de Gerente Regional de Capacitación puede otorgarse  con el",
    "infoRegionManagerDetail2": "formulario de administración regional",
    "infoUnionManagerheader": "Rol de Gerente de Capacitación de la Unión",
    "infoUnionManagerDetail1": "El Rol de Gerente de Capacitación de la Unión puede otorgarse  con ",
    "infoUnionManagerDetail2": "el formulario de administración de la Unión",
    "userRoles": "los roles de usuario",
    "infoRolesUpdatedHeader": "Los roles actualizados",
    "infoRolesUpdatedBody": "Los roles de los usuarios han sido actualizados con éxito",
    "userExactEmail": "Utilizar el correo electrónico exacto",
    "select": "Seleccionar",
    "noUsers": "No hay usuarios",
    "searchAttendee": "Buscar un participante para agregar"
  },
  "reports": {
    "reports": "Informes",
    "report": "Informe",
    "deliveryType": "Tipo de dictado",
    "actionGetReport": "Obtener informe",
    "regions": "Región(es)",
    "unions": "Unión(es)"
  },
  "qualityAudit": {
    "manage": "Gestionar",
    "qualityAssurance": "Control de calidad",
    "qualityAudits": "Auditorías de calidad",
    "date": "Fecha",
    "addQualityAudit": "Agregar Auditoría de calidad",
    "noAuditsAdded": "No se han añadido Auditorías a esta licencia",
    "educatorCanTeach": "Un Educador con la licencia propuesta puede impartir los siguientes cursos:",
    "auditor": "Auditor",
    "published": "Publicado",
    "actionPublish": "Publicar",
    "audits": "Auditorías",
    "assignAuditor": "Asignar auditor",
    "myAssignedQas": "Mis QA (Controles de calidad) asignados",
    "audit": "Auditoría",
    "auditBy": "Auditado por",
    "publishedBy": "Esta Auditoría fue publicada por",
    "onDate": "en",
    "venue": "Sede",
    "detailsPlaceholder": "Agregar detalles sobre cómo se realizó la auditoría. Incluir links online si fuera necesario",
    "areasOfStrength": "Fortalezas",
    "areasOfStrengthPlaceholder": "Agregar aquí las fortalezas",
    "areasForDevelopment": "Áreas de desarrollo",
    "areasForDevelopmentPlaceholder": "Agregar aquí las áreas de desarrollo",
    "agreedActionPlan": "Plan de acción acordado",
    "agreedActionPlanPlaceholder": "Agregar aquí el plan de acción acordado",
    "educatorSelfReflection": "Autorreflexión del Educador",
    "educatorSelfReflectionPlaceholder": "Agregar aquí la autorreflexión del educador",
    "grade": "Cualificación",
    "gradePlaceholder": "Seleccione la cualificación",
    "behaviour": "Comportamiento",
    "behaviourPlaceholder": "Seleccione el comportamiento",
    "saveAsDraft": "Guardar como borrador",
    "auditsOnEducator": "Auditorías sobre el Educador",
    "auditsByEducator": "Auditorías por Educador",
    "educator": "Educador",
    "addEducatorNote": "Agregar nota del Educador",
    "educatorNoteTooltip": "Las notas del Educador serán visibles para los Educadores y sus gerentes en la pestaña del historial del curso",
    "qaSubmitSuccess": "Auditoría de calidad enviada con éxito",
    "qaPublishedSuccess": "Auditoría de calidad publicada con éxito",
    "qaConfirmPublish": "¿Está seguro de que desea publicar esta Auditoría de calidad? La Auditoría será de sólo lectura una vez publicada.",
    "qaConfirmPublishTitle": "¿Publicar la Auditoría?",
    "qaLoadingText": "Obtener información sobre la Auditoría de calidad...",
    "manageAudits": "Manejar Auditorías",
    "myActiveLicences": "Mis licencias activas",
    "editQualityAudit": "Editar Auditoría de calidad",
    "submitForm": "Enviar formulario",
    "auditPublished": "Auditoría publicada",
    "user": "Usuario",
    "licenseHolder": "Titular de la licencia",
    "licenseStatus": "Estado de la licencia",
    "publishStatus": "Publicar Estado",
    "actionSubmitEducatorFeedback": "Enviar retroalimentación",
    "actionSubmitForEducatorFeedback": "Enviar para recibir retroalimentación de los auditados",
    "filterByLicenseHolder": "Filtrar por titular de la licencia",
    "filterByAuditor": "Filtrar por auditor",
    "filterByLicenseExpiry": "Filtrar por vencimiento de la licencia",
    "filterByLicenseRegion": "Filtrar por región de la licencia",
    "filterByLicenseUnion": "Filtrar por unión (a la que pertenece la licencia)",
    "filterByAuditStatus": "Filtrar por estado de auditoría",
    "auditStartDate": "Fecha de inicio de la auditoría",
    "auditEndDate": "Fecha de finalización de la auditoría",
    "auditStatus": "Estado de la auditoría",
    "auditDate": "Fecha de la auditoría"
  },
  "qualityAuditStatus": {
    "Draft": "Borrador",
    "AwaitingEducatorFeedback": "En espera de la retroalimentación del auditado",
    "AwaitingAuditeeFeedback": "En espera de la retroalimentación del auditado",
    "AwaitingPublish": "En espera de la publicación",
    "Published": "Publicado"
  },
  "termsAndConds": {
    "heading": "Aceptar los términos y condiciones de World Rugby",
    "main": "Para utilizar Iris, usted debe aceptar los siguientes términos y condiciones:",
    "educatorCodeOfConductHeading": "Código de conducta del Educador",
    "educatorLinkDescription": "El código de conducta del Educador puede consultarse en",
    "educatorAcceptCodeOfConduct": "Acepto el código de conducta del Educador"
  },
  "courseSessionAttendees": {
    "removeAttendees": "Eliminar participantes",
    "addAttendees": "Agregar participantes",
    "addAttendeesBy": "Agregar participantes por",
    "draft": "Borrador",
    "assessment": "Evaluación",
    "noAttendees": "No hay participantes",
    "noAttendeesSubTitle": "No se han agregado participantes a este curso. Haga click en el botón siguiente para agregar participantes",
    "bulkAddAttendees": "Agregar participantes masivamente por dirección de correo electrónico",
    "findExisting": "Encontrar un usuario existente",
    "registeringNew": "Registrar un usuario nuevo",
    "bulkAddByEmailA": "Agregar varios participantes a este curso. Introduzca las direcciones de correo electrónico de los participantes en el campo que sigue: una dirección de correo electrónico por línea y haga click en el botón \"Agregar participantes\".",
    "bulkAddByEmailB": "Sólo se agregarán al curso los usuarios existentes en Iris. Se le proporcionará una lista de las direcciones de correo electrónico de los participantes que no se hayan podido agregar.",
    "addAttendeeLabel": "direcciones de correo electrónico de los participantes (una por línea)",
    "addressesDoNotExist": "Las direcciones de correo electrónico de los participantes no existen en Iris",
    "emailsAreRegistered": "Las siguientes direcciones de correo electrónico no están registradas como usuarios",
    "notPermittedToAccess": "Acceso a usuarios no permitido",
    "emailsNotPermittedToAccess": "No está permitido acceder a las siguientes direcciones de correo electrónico",
    "registerNewUser": "Registre un nuevo usuario y agréguelo como participante a este curso. Haga esto solo si el usuario no existe ya en Iris.",
    "firstName": "Nombre",
    "surname": "Apellido",
    "noEmailWarning": "Si el participante no tiene una dirección de correo electrónico, se enviarán notificaciones por correo electrónico al Educador organizador",
    "registerNewUserAsAttendee": "Registrar nuevo usuario como participante",
    "draftAttendeeList": "Su borrador de lista de participantes",
    "updateAttendeeList": "¿Está seguro de que desea actualizar la lista de participantes?",
    "confirmThisAction": "Al confirmar esta acción todos los participantes recién agregados recibirán un correo electrónico introductorio sobre este curso.",
    "confirmTitle": "¿Actualizar la lista de participantes?",
    "errorAddingUserMessage": "Se ha producido un error al agregar un usuario",
    "errorAddingAttendees": "Se ha producido un error al agregar participantes"
  },
  "courseSessionHost": {
    "attendeeNotes": "Notas a los participantes",
    "attendeeNotesTooltip": "Estas notas aparecerán en los correos electrónicos de invitación a los nuevos participantes (una vez configuradas en las plantillas de correo electrónico). Escriba en el idioma seleccionado",
    "addCourse": "Agregar curso",
    "noEducatorLicensesDescription": "No tiene ninguna licencia activa. Necesita licencias de Educador activas para impartir cursos. Contacte a su Gerente Regional de Capacitación para obtener una licencia de educador.",
    "locationTooltip": ""
  },
  "courseSessionRouteTags": {
    "viewMyCourses": "Ver mis cursos",
    "viewScheduledCourses": "Ver los cursos programados",
    "hostCourse": "Organizar un curso",
    "scheduleCourse": "Programar un curso",
    "editCourse": "Editar curso",
    "viewCourse": "Ver un curso",
    "viewAttendees": "Ver los participantes",
    "viewCourseHistory": "Ver el historial del curso"
  },
  "routeTags": {
    "acceptEducatorTerms": "Aceptar los términos y condiciones del Educador"
  },
  "profileCourses": {
    "started": "Iniciado",
    "noCoursesAttended": "No ha asistido a ningún curso",
    "notAttendedAnyCourses": "Usted no ha asistido a ningún curso",
    "validCertLookupAll": "Todo",
    "validCertLookupCurrent": "Certificados válidos actuales",
    "validCertLookupExpired": "Vencido",
    "validCertLookupInProgress": "En curso"
  },
  "profileLicenses": {
    "noLicenses": "No hay licencias",
    "noLicensesMatch": "Ninguna licencia coincide con los criterios",
    "expiry": "Vencimiento"
  },
  "attendeeEmailInvite": {
    "showEmailPreview": "Mostrar vista previa del correo electrónico de invitación",
    "attendeeEmailPreview": "Vista previa del correo electrónico del participante",
    "subject": "Asunto",
    "htmlContent": "Contenido HTML",
    "textContent": "Contenido de texto"
  },
  "myDashboard": {
    "myActions": "Mis acciones",
    "recentCourseRequringApproval": "Cursos recientes que requieren aprobación",
    "attendeesAwaitingCertApproval": "Participantes a la espera de la aprobación de la certificación",
    "underMyAdmin": "Bajo mi administración",
    "recentCourses": "Cursos recientes",
    "upcomingCourses": "Próximos cursos",
    "auditsInProgress": "Auditorías en curso",
    "myRecentCourses": "Mis cursos recientes",
    "awaitingApproval": "A la espera de aprobación",
    "myUpcomingCourses": "Mis próximos cursos",
    "myQualityAudits": "Mis Auditorías de calidad",
    "myAuditsInProgress": "Mis Auditorías en curso",
    "myUpcomingAudits": "Mis próximas Auditorías",
    "recentUpcomingCourses": "Cursos recientes y próximos",
    "noCoursesSubtitle": "No tiene ningún curso reciente, ni tiene programado ningún curso próximo. Haga click en el botón que sigue para organizar un curso",
    "activeAudits": "Auditorías activas",
    "noAuditsSubtitle": "No tiene ninguna Auditoría reciente y no tiene ninguna Auditoría programada.",
    "auditsRequiringMyFeedback": "Solicita mi retroalimentación",
    "assessmentPeriodDueToExpire": "Evaluaciones a punto de vencer",
    "assessmentsExpireInPastYear": "Evaluaciones vencidas el año pasado",
    "correspondence": "Correspondence"
  },
  "fileshare": {
    "courses": "Cursos de archivos compartidos",
    "fileshareCourseMaterials": "Material de Cursos de archivos compartidos "
  },
  "correspondence": {
    "decline": "Rechazar",
    "validateCorrespondence": "Validar correspondencia",
    "id": "ID",
    "emailScheduleType": "Tipo",
    "status": "Estado",
    "targetedWorkforce": "Equipo de trabajo destinatario",
    "viewUsers": "Ver usuarios",
    "hideUsers": "Ocultar usuarios",
    "attachments": "Archivos adjuntos",
    "approve": "Aprobar",
    "validate": "Validar"
  },
  "educatorsEmail": {
    "numberOfUser": "Número de usuarios",
    "noUsersFound": "No se han encontrado usuarios",
    "workforce": "Equipo de trabajo",
    "filters": "Filters"
  },
  "compliance": {
    "compliance": "Conformidad",
    "complianceCheckEdit": "Editar verificación de conformidad",
    "complianceCheckAdd": "Añadir verificación de conformidad",
    "compliantOnDate": "Conformidad en la fecha",
    "complianceCheckSuccessfullySaved": "La verificación de conformidad se ha guardado correctamente",
    "requiredCertificates": "Certificados requeridos",
    "usersToCheck": "Usuarios a verificar",
    "confirmRemoveRequiredCertificate": "¿Está seguro que desea eliminar este certificado obligatorio?",
    "confirmRemoveUser": "¿Está seguro que desea eliminar este usuario?",
    "errorAddingUsers": "Se ha producido un error al agregar usuarios",
    "noCertificates": "No hay certificados requeridos"
  },
  "educatorList": {
    "numberOfCoursesDelivered": "Número de cursos impartidos",
    "numberOfUnionsDeliveredIn": "Número de uniones a las que se han impartido cursos"
  },
  "agents": {
    "agents": "Agentes",
    "applications": "Solicitudes de agentes",
    "updateAgentapplication": "Actualizar solicitud de agente",
    "knownAs": "Conocido como",
    "companyName": "Nombre de la empresa",
    "companyAddress": "Dirección de la empresa",
    "numberOfClients": "Número de clientes",
    "declarationOfGoodCharacter": "Declaración de honorabilidad",
    "proofOfidentity": "Prueba de identidad",
    "supportingDocumentation": "Documentación que acredita",
    "confirmReject": "Confirmar el rechazo de la solicitud",
    "confirmSaveAndProgress": "Confirmar guardar y pasar a la siguiente etapa",
    "applicationSuccessfullyUpdated": "La solicitud se ha actualizado correctamente",
    "errorUpdatingApplication": "Se ha producido un error al actualizar la solicitud",
    "agentDoesNotExist": "Esta solicitud de agente no existe",
    "hasCurrentAgentsCourseCertificate": "",
    "hasCurrentAgentsCoursePrerequisitesCertificate": ""
  },
  "auditorList": {
    "numberOfAuditsAssigned": "Número de auditorías asignadas",
    "numberOfStrandsAudited": "Número de ramas auditadas",
    "auditor": "Auditor"
  }
};
