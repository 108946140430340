export class UploadFileModel {
  fileName: string;
  url: string;
  name: string;
}

export class UploadResultModel extends UploadFileModel {
  storageEndpoint: string;
  storageContainer: string;
}
