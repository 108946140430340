import {
  HttpService,
  DownloadProvider } from "./common";
import {
  AssessmentModel,
  FileDownloadModel,
} from "@/core/models";

export const AssessmentService = {
  getAssessment(assessmentId: number) {
   return HttpService.getData<AssessmentModel>(`/assessments/${assessmentId}`);
  },

  putAssessment(model: AssessmentModel, isDraft: boolean = false, publish: boolean = false) {

    return HttpService.putModel(this.getSaveUrl(isDraft, publish), model);
  },

  postAssessment(model: AssessmentModel, isDraft: boolean = false, publish: boolean = false) {
    return HttpService.postModel(this.getSaveUrl(isDraft, publish), model);
  },

  calculateStatus(model: AssessmentModel) {
    return HttpService.postData<number|null>("/assessments/calculate-status", model);
  },

  Unpublish(assessmentId: any) {
    return HttpService.put(`/assessments/${assessmentId}/unpublish`);
  },

  async exportToPdf(assessmentId: number) {
    const response = await HttpService.getData<FileDownloadModel>(
      `/assessments/${assessmentId}/download`);

    DownloadProvider.downloadResponse(response);
  },

  getSaveUrl(isDraft: boolean = false, publish: boolean = false) {
    let baseUrl = "/assessments";
    // console.log(baseUrl, isDraft, publish);

    // isDraft and publish cannot be true at the same time
    if (isDraft) {
      return `${baseUrl}?isDraft=true`
    }

    return publish
      ? `${baseUrl}?publish=true`
      : baseUrl;
  },
};
