import { Vue } from "vue-property-decorator";
import axios, { AxiosRequestConfig } from "axios";

import { SettingsService } from "@/core/services";

export const AxiosFactory = new Vue({
  methods: {
    async create() {
      const config: AxiosRequestConfig = {
        baseURL: SettingsService.getWebApiUrl(),
        headers: {
          Pragma: "no-cache",
        },
      };

      // const token = localStorage.getItem("vue-authenticate.auth_token");
      const token = await this.$msal.getAccessToken();
      if (token && config.headers) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      return axios.create(config);
    },
  },
});
