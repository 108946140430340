export default {
  "common": {
    "name": "Nome",
    "hello": "Olá",
    "details": "Detalhes",
    "created": "Criado",
    "ok": "OK",
    "cancel": "Cancelar",
    "save": "Salvar",
    "confirm": "Confirmar",
    "saveAsDraft": "Salvar como rascunho",
    "unpublish": "",
    "id": "ID",
    "search": "Pesquisar",
    "status": "Status",
    "view": "Visualização",
    "course": "Curso",
    "union": "Federação",
    "noData": "Sem dados",
    "startDate": "Data de início",
    "startTime": "",
    "endDate": "Data de término",
    "notes": "Observações",
    "educators": "Educadores",
    "forUser": "Para o usuário",
    "regions": "Regiões",
    "unions": "Federações",
    "existingRegions": "",
    "existingUnions": "",
    "homeUnion": "",
    "expiresOn": "Expira em",
    "selectUser": "Selecionar usuário",
    "none": "Nenhum",
    "level": "Nível",
    "actionAddAll": "Adicionar todos",
    "reason": "Motivo",
    "gettingData": "Obter dados",
    "success": "Sucesso",
    "error": "Erro",
    "actionEdit": "Editar",
    "actionSubmit": "Enviar",
    "logo": "Logotipo",
    "actionDropFile": "Solte o arquivo aqui ou",
    "imageFormatsAllowed1": "Arquivos JPG/PNG/GIF permitidos",
    "forename": "Primeiro nome",
    "lastname": "Sobrenome",
    "emailAddress": "Endereço de e-mail",
    "email": "E-mail",
    "validationIsRequired": "é necessária",
    "code": "Código",
    "certified": "Certificado",
    "certExpiry": "Validade do certificado",
    "session": "Sessão",
    "yes": "Sim",
    "no": "Não",
    "actionDownload": "Download",
    "close": "Fechar",
    "user": "Usuário",
    "location": "",
    "from": "",
    "to": "",
    "type": "",
    "validationError": "",
    "validationErrorDescription": "",
    "errorSaving": "",
    "saving": "",
    "addedOn": "",
    "address": "",
    "dateOfBirth": "",
    "reject": "",
    "saveAndProgressToNextStage": "",
    "nextStageIs": "",
    "warning": "",
    "auditors": "",
    "firstName": "Nome",
    "surname": "Sobrenome"
  },
  "navigation": {
    "courses": "Cursos",
    "myCourses": "Meus cursos",
    "courseAdmin": "Administrador do curso",
    "training": "Treinamento",
    "mySessions": "Minhas sessões",
    "sessionAdmin": "Administrador de sessão",
    "dashboard": "Painel",
    "navAbout": "Sobre",
    "navAdministration": "Administrações",
    "navUnions": "Federações",
    "logout": "Sair",
    "myProfile": "Meu perfil",
    "coursesAttended": "Cursos presenciados",
    "licensesAwarded": "Licenças concedidas",
    "certification": "",
    "fileshare": "",
    "correspondence": "Correspondência"
  },
  "mySessions": {
    "viewMyTrainingSession": "Visualizar minhas sessões de treinamento",
    "hostTrainingSession": "Organizar uma sessão de treinamento",
    "selectCourse": "Selecione o curso",
    "selectUnion": "Selecione a federação",
    "selectLicense": "Selecione a licença",
    "licenseInfo": "Informações sobre licenças",
    "validIn": "Válido em",
    "language": "Idioma",
    "unionToAdmin": "Federação a administrar"
  },
  "filters": {
    "filterByRegion": "Filtrar por região",
    "filterByUnion": "Filtrar por federação",
    "filterByStatus": "Filtrar por status",
    "filterByQualityStatus": "Filtrar por status de qualidade",
    "filterByLevel": "Filtrar por nível",
    "filterByStrand": "Filtrar por elemento",
    "filterByExpiry": "Filtrar por validade",
    "filterByDeliveryType": "Filtrar por tipo de entrega",
    "pleaseSelectLevel": "Selecione o nível",
    "pleaseSelectStrand": "Selecione a posição",
    "pleaseSelectRegions": "Selecione as regiões",
    "pleaseSelectUnions": "Selecione as federações",
    "pleaseSelectStatus": "Selecione o status",
    "filterByLanguage": "",
    "filterByCourse": "",
    "filterByAttendeeRegion": "",
    "filterByAttendeeUnion": "",
    "filterByUserEmail": "",
    "filterByAssessmentStatus": "",
    "filterByQualityAuditStatus": ""
  },
  "sessionAdmin": {
    "viewScheduledTrainingSession": "Visualizar as sessões de treinamento agendadas",
    "scheduleTrainingSession": "Agendar uma sessão de treinamento",
    "addTrainingSession": "Adicionar sessão de treinamento"
  },
  "editTrainingSession": {
    "editTrainingSession": "Editar sessão de treinamento",
    "scheduledTraining": "Treinamento agendado",
    "certificateLanguage": "Certificar idioma",
    "venueName": "Nome do local de treinamento",
    "venueAddress": "Endereço do local de treinamento",
    "organiser": "Organizador",
    "selectAnEducator": "Selecione um educador",
    "addEducator": "Adicionar educador",
    "addNoteToHistory": "Adicionar observação ao histórico",
    "approve": "Aprovar",
    "saveTrainingSession": "Salvar sessão de treinamento",
    "viewTrainingSessionHistory": "Visualizar histórico de sessão de treinamento",
    "noHistory": "Nenhum histórico para exibir",
    "addEducatorWarning": "Esse educador pode auxiliar, mas não pode conduzir essa sessão. Cada sessão requer, pelo menos, um educador principal.",
    "assistingEducators": "Educadores auxiliares",
    "canLead": "Pode conduzir?",
    "selectLanguage": "Selecione o idioma",
    "scheduleCourse": "Programar um curso",
    "saveCourse": "Salvar curso",
    "addCourse": "Adicionar curso",
    "emailLanguageTooltip": "Os e-mails aos participantes serão escritos neste idioma",
    "startTimeLocalTime": ""
  },
  "addNote": {
    "addNote": "Adicionar observação",
    "noteDetails": "Detalhes da observação"
  },
  "courseSessionStatus": {
    "awaitingApproval": "Aguardando aprovação",
    "rejected": "Rejeitado",
    "approved": "Aprovado"
  },
  "tabs": {
    "tabattendees": "Participantes",
    "tabhistory": "Histórico",
    "tabCourseMaterials": "Materiais do curso"
  },
  "addCourseAttendees": {
    "titleViewTrainingSessionAttendees": "Visualizar participantes da sessão de treinamento",
    "editAttendeeList": "Editar lista de participantes",
    "attendeeName": "Nome",
    "assessmentStatus": "Status da avaliação",
    "actions": "Ações",
    "editAttendees": "Editar participantes",
    "actionConfirm": "Confirmar",
    "noUsersFound": "Nenhum usuário encontrado",
    "attendeeUpdateError": "Erro de atualização de participante",
    "attendeeUpdateErrorDescription": "Ocorreu um erro ao atualizar a lista de participantes. Há algum modelo de e-mail configurado para o local da sessão?",
    "optionSelectStatus": "Selecionar status",
    "actionUpdateStatus": "Atualizar status",
    "actionAdd": "Adicionar",
    "titleAddAssessment": "Adicionar avaliação",
    "selectLanguage": "Selecionar idioma",
    "actionLicense": "Licença",
    "actionLicenseDescription": "Conceder licença ao participante",
    "actionEdit": "Editar",
    "unmetPrerequisites": "Pré-requisitos não atendidos",
    "approveAreYouSure": "Tem certeza de que deseja aprovar a certificação para os participantes selecionados",
    "approveCertification": "Aprovar certificação",
    "errorUpdatingStatusTitle": "Erro ao atualizar status",
    "errorUpdatingStatusMessage": "Ocorreu um erro ao atualizar o status do participante",
    "sendAttendeeInvites": "",
    "sendAttendeeInvitesToolTip": ""
  },
  "editAssessment": {
    "editAssessment": "Editar avaliação",
    "competency": "Competência",
    "notYetCompetent": "Ainda não é competente",
    "notCompetent": "",
    "competent": "Competente",
    "excellent": "Excelente",
    "criteria": "Critérios",
    "commentHelpText": "Comentário — Obrigatório para “Ainda não é competente”",
    "actionSaveAndPublish": "Salvar e publicar",
    "assessorComment": "Comentário do avaliador",
    "publishedBy": "Publicado por",
    "lastUpdatedBy": "Atualizado pela última vez por",
    "addedBy": "Adicionado por",
    "errorSavingAssessment": "Erro ao salvar avaliação",
    "errorSavingAssessmentMessage": "Verifique se todas as entradas foram válidas",
    "confirmCancelAssessment": "Tem certeza de que deseja cancelar?",
    "leaveAssessment": "Sair da avaliação?",
    "confirmPublishAssessment": "Tem certeza de que deseja publicar essa avaliação? Após a publicação, a avaliação não poderá ser editada",
    "publishAssessment": "Publicar avaliação?",
    "viewAssessment": "Visualizar avaliação",
    "attendeeSelfReflection": "Autorreflexão do participante",
    "postCourseProgress": "",
    "postLiveExperienceProgress": "",
    "attendeeNotCompetentHeading": "",
    "attendeeMayBeCompetent": "",
    "attendeeNotCompetent": "",
    "attendeeNotCompetentConfirmation": "",
    "unpublishAssessmentConfirmation": "",
    "infoPublishAssessment": "",
    "publishAssessmentSwitchLabel": "",
    "publishAssessmentActiveLabel": "",
    "publishAssessmentInactiveLabel": ""
  },
  "feedback": {
    "viewFeedback": "Visualizar feedback"
  },
  "activeCerts": {
    "activeCertificateStatistics": "Estatísticas de certificados ativos",
    "date1": "Data 1",
    "date2": "Data 2",
    "activeCerts": "Certificados ativos",
    "pickDate": "Escolha uma data",
    "total": "Total"
  },
  "issuedCerts": {
    "issuedCertificateStatistics": "Estatísticas de certificados emitidos",
    "filterByDeliveryType": "Filtrar por tipo de entrega",
    "range1": "Faixa 1",
    "range2": "Faixa 2",
    "dateTo": "Para",
    "regionUserFilters": "Filtros de região/usuário",
    "regionUserFiltersDescription1": "Filtros de região e federação são aplicados de maneiras diferentes, dependendo do tipo de entrega do curso:",
    "regionUserFiltersDescription2": "On-line e Scorm: as estatísticas são agrupadas por federação registrada para o participante",
    "regionUserFiltersDescription3": "Presencial: as estatísticas são agrupadas por federação na qual o treinamento ocorreu",
    "courseCode": "Código do curso",
    "courseName": "Nome do curso",
    "issuedCerts1": "Certificados emitidos 1",
    "issuedCerts2": "Certificados emitidos 2",
    "percentageIncrease": "% de aumento",
    "range1Start": "Início da Faixa 1",
    "range1End": "Fim da Faixa 1",
    "range2Start": "Início da Faixa 2",
    "range2End": "Fim da Faixa 2"
  },
  "strands": {
    "strandRugbyReady": "Rugby ready",
    "strandLaws": "Regras do jogo",
    "strandStrengthAndConditioning": "Força e condicionamento",
    "strandPlayerWelfare": "Bem-estar dos jogadores",
    "strandKeepRugbyClean": "Keep Rugby Clean",
    "strandCoaching": "Treinamento",
    "strandOfficiating": "Atuação como juiz",
    "strandIntegrity": "Integridade",
    "strandMatchDayStaff": "Equipe do dia do jogo",
    "strand": "Elemento"
  },
  "licenseStatus": {
    "licenseActive": "Ativo",
    "licenseRevoked": "Revogado",
    "licenseExpired": "Expirado",
    "licenseProvisional": "Provisório"
  },
  "licenses": {
    "licenses": "Licenças",
    "grantEducatorLicense": "Conceder licença de educador",
    "actionRevoke": "Revogar",
    "grantALicense": "Conceder uma licença",
    "grantLicense": "Conceder licença",
    "certificatesAwarded": "Certificados concedidos",
    "actionGrantALicense": "Conceder uma licença",
    "licenseRevoke": "Revogar licença",
    "revokeLicense": "Revogar licença",
    "noRevokeEmailHeader": "Sem e-mail automático de revogação de licença",
    "noRevokeEmailBody": "Não é enviado um e-mail automático quando uma licença é revogada. Os administradores podem desejar informar a ação pessoalmente",
    "viewEligibleCourses": "Visualizar cursos autorizados",
    "eligibleCourses": "Cursos autorizados",
    "messageLicenceAwardDelay": "Há um período de espera entra a concessão de um certificado a um participante por um gerente e a emissão do certificado para o participante. A tabela abaixo mostra somente certificados concedidos, então é possível que um certificado concedido recentemente a um participante não seja exibido nela.",
    "messageEducatorCanTeach": "Um educador com a licença proposta pode lecionar os seguintes cursos",
    "licenseAwardedTo": "Licença concedida a",
    "errorAddingLicense": "Ocorreu um erro ao adicionar esta licença",
    "gettingLicenseInformation": "Obter informações sobre licenças",
    "noLicenses": "Você não possui nenhuma licença ativa no momento",
    "licenseHistory": "Histórico de licenças",
    "qualityStatus": "Status de qualidade",
    "delayIssuingCertificate": "Observação: atraso na emissão de certificados",
    "delayIssuingCertificateInfo": "Há um período de espera entra a concessão de um certificado a um participante por um gerente e a emissão do certificado para o participante. A tabela abaixo mostra somente certificados concedidos, então é possível que um certificado concedido recentemente a um participante não seja exibido nela.",
    "noActiveLicenses": "Nenhuma licença ativa",
    "addLicenseError": "Ocorreu um erro ao adicionar esta licença",
    "myLicenses": "Minhas licenças",
    "emailWorkforce": "Email equipe",
    "licenseAmended": "",
    "licenseAmendedConfirmationHeading": "",
    "licenseAmendConfirmation": "",
    "expireLicense": "",
    "amendLicense": ""
  },
  "regionAdmin": {
    "rtmUser": "Usuário do RTM",
    "saveRegion": "Salvar região"
  },
  "unionAdmin": {
    "unionTrainingEducationAdministrator": "Administrador de viagens e despesas da federação",
    "canDeliverCoursesForWr": "Pode lecionar cursos em nome da World Rugby",
    "saveUnion": "Salvar federação",
    "gettingUnion": "Obter federação",
    "unionSaved": "Federação salva"
  },
  "certStatus": {
    "certStatusInProgress": "Em andamento",
    "certStatusValid": "válido",
    "certStatusInExpired": "Expirado",
    "certStatusAll": "Todos"
  },
  "userAdmin": {
    "userList": "Lista de usuários",
    "inviteUser": "Convidar usuário",
    "infoNoEmailInviteHeader": "Nenhum e-mail convite será enviado",
    "infoNoEmailInviteBody1": "Esta função adiciona um usuário ao banco de dados com a função de backend na Iris",
    "infoNoEmailInviteBody2": "Nenhum e-mail convite será enviado ao usuário como parte deste recurso, então é responsabilidade do administrador da Iris notificá-lo sobre a sua adição.",
    "infoNoEmailInviteBody3": "Se o usuário ainda não o tiver feito, ele deve se registrar junto ao WR SSO usando o endereço de e-mail informado aqui.",
    "actionAddUserAndAssignBackendRole": " Adicionar usuário e atribuir função de backend",
    "irisUserAddedHeader": "Usuário de backend da Iris adicionado",
    "irisUserAddedBody": "foi adicionado como um usuário de backend da Iris",
    "errorAddingUserHeader": "Erro ao adicionar usuário de backend",
    "errorAddingUserBody": "Ocorreu um erro ao adicionar o usuário de backend",
    "isExactEmail": "É o e-mail exato",
    "userBaseId": "ID da base de usuários",
    "lastLoginDate": "Data do último login",
    "coursesAndCertificates": "Cursos e certificados",
    "roles": "Funções",
    "currentValidCertificates": "Certificados válidos atuais",
    "userLicenses": "Licenças de usuário",
    "userNeverGrantedLicense": "Nenhuma licença jamais foi concedida a este usuário",
    "infoRegionManagerheader": "Função de gerente de treinamento regional",
    "infoRegionManagerDetail1": "A função de gerente de treinamento regional pode ser concedida no",
    "infoRegionManagerDetail2": "formulário de administração de região",
    "infoUnionManagerheader": "Função de gerente de treinamento de federação",
    "infoUnionManagerDetail1": "A função de gerente de treinamento de federação pode ser concedida no",
    "infoUnionManagerDetail2": "formulário de administração de federação",
    "userRoles": "funções de usuário",
    "infoRolesUpdatedHeader": "Funções atualizadas",
    "infoRolesUpdatedBody": "As funções de usuário foram atualizadas com sucesso",
    "userExactEmail": "Usar e-mail exato",
    "select": "Selecionar",
    "noUsers": "Nenhum usuário",
    "searchAttendee": "Pesquisar por atendente a adicionar"
  },
  "reports": {
    "reports": "Relatórios",
    "report": "Relatório",
    "deliveryType": "Tipo de entrega",
    "actionGetReport": "Obter relatório",
    "regions": "Região(ões)",
    "unions": "Federação(ões)"
  },
  "qualityAudit": {
    "manage": "Gerenciar",
    "qualityAssurance": "Garantia de qualidade",
    "qualityAudits": "Auditorias de qualidade",
    "date": "Data",
    "addQualityAudit": "Adicionar auditoria de qualidade",
    "noAuditsAdded": "Nenhuma auditoria foi adicionada a essa licença",
    "educatorCanTeach": "Um educador com a licença proposta pode lecionar os seguintes cursos:",
    "auditor": "Auditor",
    "published": "Publicado",
    "actionPublish": "Publicar",
    "audits": "Auditorias",
    "assignAuditor": "Atribuir auditor",
    "myAssignedQas": "Minhas Garantias de qualidade atribuídas",
    "audit": "Auditoria",
    "auditBy": "Auditoria por",
    "publishedBy": "Essa auditoria foi publicada por ",
    "onDate": "em",
    "venue": "Local",
    "detailsPlaceholder": "",
    "areasOfStrength": "Áreas de domínio",
    "areasOfStrengthPlaceholder": "Adicione áreas de domínio aqui",
    "areasForDevelopment": "Áreas para desenvolvimento",
    "areasForDevelopmentPlaceholder": "Adicione áreas para desenvolvimento aqui",
    "agreedActionPlan": "Plano de ação concordado",
    "agreedActionPlanPlaceholder": "Adicione o plano de ação concordado aqui",
    "educatorSelfReflection": "Autorreflexão do educador",
    "educatorSelfReflectionPlaceholder": "Adicione a autorreflexão do educador aqui",
    "grade": "Nota",
    "gradePlaceholder": "Selecione a nota",
    "behaviour": "Comportamento",
    "behaviourPlaceholder": "Selecione o comportamento",
    "saveAsDraft": "Salvar como rascunho",
    "auditsOnEducator": "Auditorias sobre o educador",
    "auditsByEducator": "Auditorias por educador",
    "educator": "Educador",
    "addEducatorNote": "Adicionar observação do educador",
    "educatorNoteTooltip": "As observações do educador ficarão visíveis para educadores e seus gerentes na guia de histórico de cursos",
    "qaSubmitSuccess": "Auditoria de qualidade enviada com êxito",
    "qaPublishedSuccess": "Auditoria de qualidade publicada com êxito",
    "qaConfirmPublish": "Tem certeza de que deseja publicar essa auditoria de qualidade? Após a publicação, a auditoria se tornará apenas leitura.",
    "qaConfirmPublishTitle": "Publicar auditoria?",
    "qaLoadingText": "Obtendo informações da Auditoria de qualidade...",
    "manageAudits": "Gerenciar auditorias",
    "myActiveLicences": "Minhas licenças ativas",
    "editQualityAudit": "Editar Auditoria de qualidade",
    "submitForm": "Enviar formulário",
    "auditPublished": "Auditoria publicada",
    "user": "Usuário",
    "licenseHolder": "Titular da licença",
    "licenseStatus": "",
    "publishStatus": "",
    "actionSubmitEducatorFeedback": "",
    "actionSubmitForEducatorFeedback": "",
    "filterByLicenseHolder": "",
    "filterByAuditor": "",
    "filterByLicenseExpiry": "",
    "filterByLicenseRegion": "",
    "filterByLicenseUnion": "",
    "filterByAuditStatus": "",
    "auditStartDate": "",
    "auditEndDate": "",
    "auditStatus": "",
    "auditDate": ""
  },
  "qualityAuditStatus": {
    "Draft": "",
    "AwaitingEducatorFeedback": "",
    "AwaitingAuditeeFeedback": "",
    "AwaitingPublish": "",
    "Published": ""
  },
  "termsAndConds": {
    "heading": "Aceitar termos e condições da World Rugby",
    "main": "Para prosseguir utilizando a Iris, você deve aceitar os seguintes termos e condições:",
    "educatorCodeOfConductHeading": "Código de conduta do educador",
    "educatorLinkDescription": "O código de conduta do educador pode ser visualizado em",
    "educatorAcceptCodeOfConduct": "Eu aceito o código de conduta do educador"
  },
  "courseSessionAttendees": {
    "removeAttendees": "Remover participantes",
    "addAttendees": "Adicionar participantes",
    "addAttendeesBy": "Adicionar participantes por",
    "draft": "Rascunho",
    "assessment": "Avaliação",
    "noAttendees": "Nenhum participante",
    "noAttendeesSubTitle": "Nenhum participante foi adicionado a esse curso. Clique no botão a seguir para adicionar participantes",
    "bulkAddAttendees": "Adição de participantes em massa por endereço de e-mail",
    "findExisting": "Localização de um usuário existente",
    "registeringNew": "Registro de um novo usuário",
    "bulkAddByEmailA": "Adicione diversos participantes a esse curso. Insira os endereços de e-mail dos participantes no campo a seguir, um endereço de e-mail por linha e clique no botão “Adicionar participantes”.",
    "bulkAddByEmailB": "Somente usuários existentes da Iris serão adicionados ao curso. Uma lista de endereços de e-mail de participantes que não podem ser adicionados será fornecida a você.",
    "addAttendeeLabel": "endereços de e-mail de participantes (um por linha)",
    "addressesDoNotExist": "Os endereços de e-mail de participantes não existem na Iris",
    "emailsAreRegistered": "Os endereços de e-mail a seguir estão registrados como usuários",
    "notPermittedToAccess": "",
    "emailsNotPermittedToAccess": "",
    "registerNewUser": "Registre um novo usuário e adicione-o como um participante desse curso. Faça isso apenas quando o usuário ainda não existir na Iris.",
    "firstName": "Nome",
    "surname": "Sobrenome",
    "noEmailWarning": "Se o participante não tiver um endereço de e-mail, as notificações por e-mail serão enviadas para o educador organizador",
    "registerNewUserAsAttendee": "Registrar novo usuário como participante",
    "draftAttendeeList": "Sua lista de participantes de rascunho",
    "updateAttendeeList": "Tem certeza de que deseja atualizar a lista de participantes?",
    "confirmThisAction": "Ao confirmar essa ação, todos os participantes adicionados recentemente receberão um e-mail de introdução sobre esse curso.",
    "confirmTitle": "Atualizar lista de participantes?",
    "errorAddingUserMessage": "Ocorreu um erro ao adicionar um usuário",
    "errorAddingAttendees": "Ocorreu um erro ao adicionar participantes"
  },
  "courseSessionHost": {
    "attendeeNotes": "Observações do participante",
    "attendeeNotesTooltip": "Essas observações aparecerão em convites por e-mail para novos participantes (após a configuração em modelos de e-mail). Escreva no idioma selecionado",
    "addCourse": "Adicionar curso",
    "noEducatorLicensesDescription": "Você não possui nenhuma licença ativa. Para lecionar cursos, são necessárias licenças de educador ativas. Entre em contato com seu gerente de treinamento Regional para receber uma licença de educador",
    "locationTooltip": ""
  },
  "courseSessionRouteTags": {
    "viewMyCourses": "Visualizar meus cursos",
    "viewScheduledCourses": "Visualizar cursos programados",
    "hostCourse": "Organizar um curso",
    "scheduleCourse": "Programar curso",
    "editCourse": "Editar curso",
    "viewCourse": "Visualizar curso",
    "viewAttendees": "Visualizar participantes",
    "viewCourseHistory": "Visualizar histórico de cursos"
  },
  "routeTags": {
    "acceptEducatorTerms": "Aceitar termos e condições do educador"
  },
  "profileCourses": {
    "started": "Iniciado",
    "noCoursesAttended": "Nenhum curso presenciado",
    "notAttendedAnyCourses": "Você não possui nenhum curso presenciado",
    "validCertLookupAll": "Todos",
    "validCertLookupCurrent": "Certificados válidos atuais",
    "validCertLookupExpired": "Expirado",
    "validCertLookupInProgress": "Em andamento"
  },
  "profileLicenses": {
    "noLicenses": "Nenhuma licença",
    "noLicensesMatch": "Nenhuma licença atende aos critérios",
    "expiry": "Expirar"
  },
  "attendeeEmailInvite": {
    "showEmailPreview": "Exibir visualização de e-mail convite",
    "attendeeEmailPreview": "Visualização de e-mail do participante",
    "subject": "Assunto",
    "htmlContent": "Conteúdo HTML",
    "textContent": "Conteúdo de texto"
  },
  "myDashboard": {
    "myActions": "Minhas ações",
    "recentCourseRequringApproval": "Cursos recentes que exigem aprovação",
    "attendeesAwaitingCertApproval": "Participantes que aguardam aprovação da certificação",
    "underMyAdmin": "Sob minha administração",
    "recentCourses": "Cursos recentes",
    "upcomingCourses": "Cursos futuros",
    "auditsInProgress": "Auditorias em andamento",
    "myRecentCourses": "Meus cursos recentes",
    "awaitingApproval": "Aguardando aprovação",
    "myUpcomingCourses": "Meus cursos futuros",
    "myQualityAudits": "Minhas auditorias de qualidade",
    "myAuditsInProgress": "Minhas auditorias em andamento",
    "myUpcomingAudits": "Minhas auditorias futuras",
    "recentUpcomingCourses": "Cursos recentes e futuros",
    "noCoursesSubtitle": "Você não possui nenhum curso recente e não tem nenhum curso futuro programado. Clique no botão abaixo para organizar um curso",
    "activeAudits": "Auditorias ativas",
    "noAuditsSubtitle": "Você não possui nenhuma auditoria recente e não tem nenhuma auditoria futura programada.",
    "auditsRequiringMyFeedback": "",
    "assessmentPeriodDueToExpire": "",
    "assessmentsExpireInPastYear": "",
    "correspondence": "Correspondência"
  },
  "fileshare": {
    "courses": "",
    "fileshareCourseMaterials": ""
  },
  "correspondence": {
    "decline": "Rejeitar",
    "validateCorrespondence": "Validar correspondencia",
    "id": "ID",
    "emailScheduleType": "Tipo",
    "status": "Estado",
    "targetedWorkforce": "Equipe direcionada",
    "viewUsers": "Ver usuários",
    "hideUsers": "Esconder usuários",
    "attachments": "Anexos",
    "approve": "Aprovar",
    "validate": "Validar"
  },
  "educatorsEmail": {
    "numberOfUser": "Número de usuários",
    "noUsersFound": "Usuários não encontrados",
    "workforce": "Equipe",
    "filters": "Filtros"
  },
  "compliance": {
    "compliance": "",
    "complianceCheckEdit": "",
    "complianceCheckAdd": "",
    "compliantOnDate": "",
    "complianceCheckSuccessfullySaved": "",
    "requiredCertificates": "",
    "usersToCheck": "",
    "confirmRemoveRequiredCertificate": "",
    "confirmRemoveUser": "",
    "errorAddingUsers": "",
    "noCertificates": ""
  },
  "educatorList": {
    "numberOfCoursesDelivered": "",
    "numberOfUnionsDeliveredIn": ""
  },
  "agents": {
    "agents": "",
    "applications": "",
    "updateAgentapplication": "",
    "knownAs": "",
    "companyName": "",
    "companyAddress": "",
    "numberOfClients": "",
    "declarationOfGoodCharacter": "",
    "proofOfidentity": "",
    "supportingDocumentation": "",
    "confirmReject": "",
    "confirmSaveAndProgress": "",
    "applicationSuccessfullyUpdated": "",
    "errorUpdatingApplication": "",
    "agentDoesNotExist": "",
    "hasCurrentAgentsCourseCertificate": "",
    "hasCurrentAgentsCoursePrerequisitesCertificate": ""
  },
  "auditorList": {
    "numberOfAuditsAssigned": "",
    "numberOfStrandsAudited": "",
    "auditor": ""
  }
};
