









import { Component, Prop, Vue } from "vue-property-decorator";

import { SettingsService } from "@/core/services";

@Component
export default class HelloWorld extends Vue {
  // data variables
  protected environment: string = "";
  protected webapiUrl: string = "";

  @Prop() private msg!: string;
  @Prop() private subHeading!: string;

  // methods
  protected created(): void {
    this.environment = process.env.NODE_ENV;
    this.webapiUrl = SettingsService.getWebApiUrl();
  }

  get helloWorld() {
    return this.$t("common.hello");
  }
}
