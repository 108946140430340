export const SettingsService = {

  getWebApiUrl() {
    return process.env.VUE_APP_WEBAPI_URL;
  },

  getB2CClientId() {
    return process.env.VUE_APP_B2C_CLIENT_ID;
  },

  getB2CSignInAuthority() {
    return process.env.VUE_APP_B2C_SIGN_IN_AUTHORITY;
  },

  getB2CPassportResetAuthority() {
    return process.env.VUE_APP_B2C_PASSWORD_RESET_AUTHORITY;
  },

  getB2CSignInTotpAuthority() {
    return process.env.VUE_APP_B2C_SIGN_IN_TOTP_MFA_AUTHORITY;
  },

  getApiWriteScope() {
    return process.env.VUE_APP_IRIS_API_WRITE_SCOPE;
  },

  getRedirectUri() {
    return process.env.VUE_APP_REDIRECT_URI;
  },

  getAppOwnerLoginPage() {
    return process.env.VUE_APP_OWNER_LOGIN_PAGE;
  },

  getAppOwnerNoAccessPage() {
    return process.env.VUE_APP_OWNER_NO_ACCESS_PAGE;
  },

  getCheckForEducatorTermsAndConditionsValue() {
    return process.env.VUE_APP_CHECK_FOR_EDUCATOR_TERMS_AND_CONDITIONS;
  },

  getCheckForEducatorTermsAndConditions() {
    return this.getCheckForEducatorTermsAndConditionsValue() === "yes";
  }
};