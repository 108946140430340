export enum Language {
  EN = "en",
  ES = "es",
  FR = "fr",
  JA = "ja",
  RU = "ru",
  RO = "Ro",
  PTBR = "pt-br",
  NL = "nl",
  DE = "de",
  ZHCN = "zh-cn",

  // Frontend only languages
  AR = "ar",
  AF = "af",
  CS = "cs",
  DA = "da",
  IT = "it",
  KA = "ka",
  PL = "pl",
  HU = "hu",
}

export const IrisLanguages = [
  { value: Language.EN, caption: "English" },
  { value: Language.ES, caption: "Español" },
  { value: Language.FR, caption: "Français" },
  { value: Language.JA, caption: "日本語 (JA)" },
  { value: Language.ZHCN, caption: "中文 (ZH-CN)" },
  { value: Language.DE, caption: "Deutsch" },
  { value: Language.NL, caption: "Nederlands" },
  { value: Language.PTBR, caption: "Portugués (BR)" },
  { value: Language.RO, caption: "Româna" },
  { value: Language.RU, caption: "Русский" },
];

export const PassportFrontEndLanguages = [
 ... IrisLanguages,
 { value: Language.AR, caption: "العربيّة (AR)" },
 { value: Language.AF, caption: "Afrikaans" },
 { value: Language.CS, caption: "Ceština (cs)" },
 { value: Language.DA, caption: "Dansk" },
 { value: Language.IT, caption: "Italiano" },
 { value: Language.KA, caption: "Georgian" },
 { value: Language.HU, caption: "Magyar" },
 { value: Language.PL, caption: "Polski" },
];