















































































































































































































import { Component, Watch } from "vue-property-decorator";
import _ from "lodash";

import {
  AssessmentModel,
  AssessmentLookupsModel,
} from "@/core/models";
import {
  AssessmentService,
  AssessmentTranscriptService,
} from "@/core/services";
import {
  AssessmentCriteriaStatus,
  PublishStatus,
} from "@/core/constants";

import InputField from "@/components/common/form/InputField.vue";
import InputSwitch from "@/components/common/form/InputSwitch.vue";
import BaseFormComponent from "@/components/common/form/BaseFormComponent.vue";
import { Map } from "@/core/models";

@Component({
  components: {
    InputField,
    InputSwitch,
  },
})
export default class AssessmentForm extends BaseFormComponent {
  lookups: AssessmentLookupsModel = new AssessmentLookupsModel();
  model: AssessmentModel = new AssessmentModel();
  isDirty: boolean = false;
  notYetCompetentId: number = AssessmentCriteriaStatus.NotYetCompetent;
  incompleteId: number = AssessmentCriteriaStatus.Incomplete;
  CompetentId: number = AssessmentCriteriaStatus.Competent;
  ExcellentId: number = AssessmentCriteriaStatus.Excellent;
  assessmentCriteriaStatusId: number|null = null;
  assessmentCriteriaStatusDescription: string = "";
  assessmentCriteriaStatusClass: string = "info";
  minRows: number = 2;
  maxRows: number = 8;
  showCollapseSections: string[] = [];
  showPublishDialog = false;
  publishAssessment = true;
  modelState?: Map<string[]> = {};

  get courseSessionId() {
    return this.$route.params.id;
  }

  get courseAttendeeId() {
    return this.$route.params.courseAttendeeId;
  }

  get locale() {
    return this.$route.params.locale;
  }

  get assessmentId() {
    return this.$route.params.assessmentId;
  }

  get isDraft() {
    return this.model.publishStatusId === PublishStatus.Draft;
  }

  get isAdminView() {
    return this.$route.query.adminView === "y";
  }

  get showPostCourseProgress() {
    return !!this.model.postCourseProgress && this.model.postCourseProgress.length > 0;
  }

  get showAfterLiveExperienceProgress() {
    return !!this.model.afterLiveExperienceProgress && this.model.afterLiveExperienceProgress.length > 0;
  }

  get isPublishDisabled() {
    if (this.model.attendeeIsNotCompetent) {
      return false;
    }

    return !this.assessmentCriteriaStatusId 
      || this.assessmentCriteriaStatusId === this.notYetCompetentId
      || this.assessmentCriteriaStatusId === this.incompleteId
  }

  get canbePublished() {
    return this.assessmentCriteriaStatusId === this.CompetentId
      || this.assessmentCriteriaStatusId === this.ExcellentId;
  }

  async created() {
    await this.bindData();
  }

  async onClickSubmit() {
    this.publishAssessment = false;

    if (this.canbePublished) {
      this.showPublishDialog = true;
    } else {
      await this.onSubmitForm();
    }
  }

  async onSubmitForm() {
      try {
        if (this.publishAssessment) {
          await this.onPublish();
        } else {
          await this.saveAssessment();
        }
      }
      finally {
        this.showPublishDialog = false;
      }
  }

  async onExportToPdf() {
    await AssessmentService.exportToPdf(+this.assessmentId);
  }

  async saveAssessment(isDraft: boolean = false, publish: boolean = false) {
    const loading = this.$loading({
      lock: true,
      text: "Processing ...",
    });

    try {
      await this.doSaveAssessment(isDraft, publish);

      this.goToAttendees();
    } catch {
      this.$notify.error({
          title: this.localiseString("editAssessment.errorSavingAssessment"),
          message: this.localiseString("editAssessment.errorSavingAssessmentMessage"),
        });

        this.modelState = !this.model.modelState ? {} : _.cloneDeep(this.model.modelState);
    } finally {
      loading.close();
    }
  }

  private async doSaveAssessment(isDraft: boolean, publish: boolean) {
    this.assessmentId
        ? await AssessmentService.putAssessment(this.model, isDraft, publish)
        : await AssessmentService.postAssessment(this.model, isDraft, publish);
  }

  private async initializeLookups() {
    // this.lookups = await AssessmentService.getAssessmentLookups();
    this.lookups.criteriaStatuses.push({
      id: AssessmentCriteriaStatus.NotYetCompetent as number,
      code: "NYC",
      text: this.localiseString("editAssessment.notYetCompetent"),
    });
    this.lookups.criteriaStatuses.push({
      id: AssessmentCriteriaStatus.Competent as number,
      code: "C",
      text: this.localiseString("editAssessment.competent"),
    });
    this.lookups.criteriaStatuses.push({
      id: AssessmentCriteriaStatus.Excellent as number,
      code: "E",
      text: this.localiseString("editAssessment.excellent"),
    });
  }

  @Watch("$route", { deep: true })
  private async bindData() {
    const loading = this.$loading({
      lock: true,
      text: this.localiseString("common.gettingData"),
    });

    try {
      await this.initializeLookups();
      await this.initializeModel();

      if (this.model.attendeeIsNotCompetent) {
        this.showCollapseSections.push("attendeeCompetency");
      }
      
    } catch (err) {
      this.handleApiGetError(err);
    } finally {
      loading.close();
    }
  }

  private async initializeModel() {
    if (this.assessmentId == null) {
      await this.createNewAssessment();
    } else {
        this.model = await AssessmentService.getAssessment(+this.assessmentId);
    }

    const statusId = await AssessmentService.calculateStatus(this.model);
    this.setStatusDescription(statusId);
    this.isDirty = false;
  }

  private async createNewAssessment() {
    // Fetch the transcript and create a model based on the transcript criteria
    const assessmentTranscript = await AssessmentTranscriptService
      .getForCourseSession(+this.courseSessionId, this.locale);

    this.model.localeId = assessmentTranscript.localeId;
    this.model.courseAttendeeId = +this.courseAttendeeId;

    _.extend(this.model, assessmentTranscript);
  }

  private goToAttendees() {
    this.$router.push({
      name: "courseSessionAttendees",
      params: {
        id: this.courseSessionId,
      },
    } as any);
  }

  private getCriteriaCommentErrorModel(competencyIndex: number, criteriaIndex: number) {
    const commentPath = `competencies[${competencyIndex}].Criteria[${criteriaIndex}].Comment`;
    const model = this.getErrorModel(commentPath);

    return !model ? null : model[0];
  }

  private getCriteriaStatusErrorModel(competencyIndex: number, criteriaIndex: number) {
    const commentPath = `competencies[${competencyIndex}].Criteria[${criteriaIndex}].AssessmentCriteriaStatusId`;
    const model = this.getErrorModel(commentPath);

    return !model ? null : model[0];
  }

  private getErrorModel(path: string) {

    if (this.modelState && this.modelState[path]) {
      return this.modelState[path];
    }

    return null;
  }

  private onCancel() {
    if (!this.isDirty || !this.isDraft) {
      this.goToAttendees();

      return;
    }

    this.$confirm(this.localiseString("editAssessment.confirmCancelAssessment"),
      this.localiseString("editAssessment.leaveAssessment"), {
        confirmButtonText: this.localiseString("common.ok"),
        cancelButtonText: this.localiseString("common.cancel"),
        type: "warning",
    })
    .then(() => {
      this.goToAttendees();
    });
  }

  protected onUnpublish() {
    this.$confirm(this.localiseString("editAssessment.unpublishAssessmentConfirmation"),
      this.localiseString("common.unpublish"), {
        confirmButtonText: this.localiseString("common.ok"),
        cancelButtonText: this.localiseString("common.cancel"),
        type: "warning",
    })
    .then(() => {
      AssessmentService.Unpublish(this.model.assessmentId);

      this.goToAttendees();
    });
  }

  private async onCriteriaChange() {
    const statusId = await AssessmentService.calculateStatus(this.model);
    this.setStatusDescription(statusId);
  }

  private setStatusDescription(criteriaStatusId: number|null) {
    this.assessmentCriteriaStatusId = criteriaStatusId;
    this.assessmentCriteriaStatusClass = this.getStatusClass(criteriaStatusId);
    if (!criteriaStatusId) {
      this.assessmentCriteriaStatusDescription = "N/A";

      return;
    }

    const statusLookup = _.find(this.lookups.criteriaStatuses, {id: criteriaStatusId});
    if (!statusLookup) {
      this.assessmentCriteriaStatusDescription = "N/A";

      return;
    }

    this.assessmentCriteriaStatusDescription = statusLookup.text;
  }

  private getStatusClass(criteriaStatusId: number|null) {
    switch (criteriaStatusId) {
      case AssessmentCriteriaStatus.NotYetCompetent: {
        return "warning";
      }
      case AssessmentCriteriaStatus.Competent: {
        return "";
      }
      case AssessmentCriteriaStatus.Excellent: {
        return "success";
      }
    }

    return "info";
  }

  @Watch("model", { deep: true})
  private onFormChange() {
    this.isDirty = true;
  }

  private async onSaveDraft() {
    try {
        await this.saveAssessment(true, false);
      } catch {
        // Update model with model errors
        this.$forceUpdate();
      }
  }

  private async onPublish() {
    this.$confirm(
      this.localiseString("editAssessment.confirmPublishAssessment"),
      this.localiseString("editAssessment.publishAssessment"), {
        confirmButtonText: this.localiseString("common.ok"),
        cancelButtonText: this.localiseString("common.cancel"),
        type: "warning",
    })
    .then(async () => {
      try {
        await this.saveAssessment(false, true);
      } catch {
        // Update model with model errors
        this.$forceUpdate();
      }
    });
  }

  private getRoute(routeName: string) {
    const route = {
      name: routeName,
      params: {
        id: this.courseSessionId,
      },
    } as any;

    if (this.isAdminView) {
      if (!route.query) {
        route.query = {};
      }

      route.query.adminView = "y";
    }

    return route;
  }
}
